import React from 'react';
import Container from 'react-bootstrap/Container';

class Master extends React.Component {
  /**
   * Render component
   * @returns {JSX.Element}
   */
  render () {
    return (
      <Container className="box">
        <div className="text-center">
          <h3>Obliczanie faktur kosztowych</h3>
        </div>
        <hr className="mt-4 mb-4" />
        {this.props.children}
      </Container>
    );
  }
}

export default Master;
