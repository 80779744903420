import React from 'react';
import Calculate from './Calculate';
import Error from './Error';

class Result extends React.Component {
  /**
   * Render component
   * @returns {JSX.Element}
   */
  render () {
    const notEmpty = typeof this.props.data.fullInvoice !== 'undefined' && typeof this.props.data.costInvoiceNet !== 'undefined';

    return (
      <>
        {notEmpty
          ? <Calculate data={this.props.data} />
          : <Error />
        }
      </>
    );
  }
}

export default Result;
