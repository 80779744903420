import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class IndexForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Handle form submit
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();
    this.props.onFormSubmit(true);
  }

  /**
   * Handle input change
   * @param event
   */
  handleChange(event) {
    this.props.onInputChange({
        ...this.state,
        [event.target.id]: parseFloat(event.target.value)
    });
  }

  /**
   * Render component
   * @returns {JSX.Element}
   */
  render () {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="mb-3">
          <label htmlFor="fullInvoice" className="col-sm-4 col-form-label">Faktura netto</label>

          <Col sm={8}>
            <input type="number" step="0.01" className="form-control" id="fullInvoice" onChange={this.handleChange} placeholder="Wpisz liczbę..." />
          </Col>
        </Row>
        <Row className="mb-3">
          <label htmlFor="costInvoiceNet" className="col-sm-4 col-form-label">Kwota z faktur netto</label>

          <Col sm={8}>
            <input type="number" step="0.01" className="form-control" id="costInvoiceNet" onChange={this.handleChange} placeholder="Wpisz liczbę..." />
          </Col>
        </Row>

        <div className="d-grid mt-4">
          <Button type="submit" variant="secondary">Oblicz</Button>
        </div>
      </Form>
    );
  }
}

export default IndexForm;
