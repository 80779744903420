import React from 'react';

class Calculate extends React.Component {
  /**
   * Render component
   * @returns {JSX.Element}
   */
  render () {
    const fullInvoice = this.props.data.fullInvoice;
    const costInvoiceNet = this.props.data.costInvoiceNet;
    const invoiceMinusInvoiceCost = fullInvoice - costInvoiceNet;
    const invoiceMinusTax = (0.09 * invoiceMinusInvoiceCost).toFixed(2);
    const invoiceFullMinusTax = fullInvoice - invoiceMinusTax;

    return (
      <>
        <div className="row">
          <div className="col" style={{fontSize: '.8em'}}>Kwota wystawionej faktury bez VAT:</div>
          <div className="col d-flex align-items-center font-value"><span className="badge bg-success">{fullInvoice} PLN</span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col" style={{fontSize: '.8em'}}>Kwota z faktur kosztowych netto:</div>
          <div className="col d-flex align-items-center font-value"><span className="badge bg-success">{costInvoiceNet} PLN</span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col" style={{fontSize: '.8em'}}>9% podatku po fakturach kosztowych:</div>
          <div className="col d-flex align-items-center">
            <span className="badge bg-success font-value">{invoiceMinusTax} PLN</span></div>
        </div>
        <hr />
        <div className="row mt-4">
          <div className="col"><h5>Suma:</h5></div>
          <div className="col d-flex align-items-center"><h5>
            <span className="badge bg-success font-value">{invoiceFullMinusTax.toFixed(2)} PLN</span></h5></div>
        </div>
      </>
    );
  }
}

export default Calculate;
