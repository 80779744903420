import './App.css';
import React from 'react';
import IndexForm from './IndexForm';
import Result from './Result';
import Master from './Master';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      submitted: false,
      fullInvoice: 0,
      costInvoiceNet: 0,
      invoiceVat: 0
    };
  }

  /**
   * Handle form submit
   * @param change
   */
  handleFormSubmit(change) {
    this.setState({
      submitted: change
    });
  }

  /**
   * Handle input change
   * @param inputChange
   */
  handleInputChange(inputChange) {
    this.setState(inputChange);
  }

  /**
   * Render component
   * @returns {JSX.Element}
   */
  render() {
    const submitted = this.state.submitted;
    const data = {
      fullInvoice: this.state.fullInvoice,
      costInvoiceNet: this.state.costInvoiceNet,
      invoiceVat: this.state.invoiceVat
    };

    return (
      <Master>
        {submitted
          ? <Result data={data} />
          : <IndexForm onInputChange={this.handleInputChange} onFormSubmit={this.handleFormSubmit} />
        }
      </Master>
    );
  }
}

export default App;
