import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class Error extends React.Component {
  /**
   * Render component
   * @returns {JSX.Element}
   */
  render () {
    return (
      <>
        <Alert variant={'danger'} className={'text-center'}>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Wszystkie pola w formularzu muszą zostać wypełnione.
        </Alert>

        <div className="d-grid mt-4">
          <a href={'/'} className={'btn btn-secondary'}>Wróć</a>
        </div>
      </>
    );
  }
}

export default Error;
